// diagnostic-page.ts
import { LitElement, html, css } from 'lit';
import { customElement } from 'lit/decorators.js';
import './patient-options-page';
@customElement('diagnostic-page')
export class DiagnosticPage extends LitElement {
  static styles = css`
    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 16px;
    }

    .result-box {
      width: 80%;
      height: 100px;
      background-color: #f0f0f0;
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      color: #333;
    }

    button {
      width: 80%;
      padding: 12px;
      background-color: #1e90ff;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      margin-top: 16px;
      font-size: 16px;
    }

    button:hover {
      background-color: #005cbf;
    }

    h3 {
      margin-top: 0;
      text-align: center;
    }
  `;

  render() {
    return html`
      <div class="container">
        <h3>Diagnostic page</h3>

        <div class="result-box">History Results</div>
        <div class="result-box">Image Results</div>

        <h3>Final results and flagged symptoms</h3>

        <div class="result-box">Prescribed treatment plan</div>

        <button @click="${this._continue}">Continue</button>
        <button @click="${this._goBack}">Back</button>
      </div>
    `;
  }

  _continue() {
    console.log('Continuing to the next step...');
    // Logic to navigate to the next page or perform an action

    sessionStorage.setItem('lastPage','diagnostic-page');
    const patientOptions = document.createElement('patient-options-page');
    this.replaceWith(patientOptions);
  }

  _goBack() {

    const lastPage = sessionStorage.getItem('lastPage');
    if (lastPage) {
        const lastPageElement = document.createElement(lastPage);
        this.replaceWith(lastPageElement);
    }
  }
}
