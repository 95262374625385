import { LitElement, html, css } from 'lit';
import { customElement } from 'lit/decorators.js';

import './wound-photo-page';
import './diagnostic-page';

@customElement('choose-patient-page')
export class ChoosePatientPage extends LitElement {


  static styles = css`
    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 16px;
    }

    .patient-selection {
      width: 80%;
      height: 250px;
      background-color: #eaeaea;
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 18px;
      color: #333;
    }

    button {
      width: 80%;
      margin-bottom: 16px;
      padding: 12px;
      background-color: #2196f3;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
    }

    button:hover {
      background-color: #1976d2;
    }
  `;

  render() {
    return html`
      <div class="container">
        <h2>Choose Patient:</h2>
        <div class="patient-selection">Patient Information Here</div>

        <button @click="${this._continueToWoundSection}">Continue to wound Section</button>
        <button @click="${this._updateHistory}">Update History</button>
        <button @click="${this._seePreviousDiagnostics}">See previous diagnostics</button>
        <button @click="${this._goBack}">Back</button>

      </div>
    `;
  }

  _continueToWoundSection() {
    sessionStorage.setItem('lastPage','choose-patient-page');
    // Logic to navigate to wound section page
    console.log('Continue to wound section');
    const woundphot = document.createElement('wound-photo-page');
    this.replaceWith(woundphot);
    // For example: window.location.href = '/wound-section';
  }

  _updateHistory() {
    sessionStorage.setItem('lastPage','choose-patient-page');
    // Logic to navigate to the update history page
    const createhistory = document.createElement('patient-history-page');
    this.replaceWith(createhistory);
    console.log('Update history');

  }

  _seePreviousDiagnostics() {
    sessionStorage.setItem('lastPage','choose-patient-page');
    // Logic to see previous diagnostics
    console.log('See previous diagnostics');
    const diagnosepage = document.createElement('diagnostic-page');
    this.replaceWith(diagnosepage);
  }

  _goBack() {
    // Logic to go back to the previous page
    const lastPage = sessionStorage.getItem('lastPage');
    if (lastPage) {
        const lastPageElement = document.createElement(lastPage);
        this.replaceWith(lastPageElement);
    }

  }
}
