import { LitElement, css, html } from 'lit';
import { customElement } from 'lit/decorators.js';

import './patient-history-page';

@customElement('create-patient-page')
export class CreatePatientPage extends LitElement {
  static styles = css`
    /* Add styles for the form */


    .container {
      padding: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-family: Arial, sans-serif;
    }
    input {
      display: block;
      width: 30%;
      padding: 10px;
      margin-bottom: 15px;
      font-size: 16px;
    }
    button {
      display: block;
      width: 30%;
      padding: 10px;
      margin-bottom: 15px;
      background-color: #2196f3;
      color: white;
      border: none;
      font-size: 16px;
      cursor: pointer;
    }
  `;

  render() {
    return html`
      <div class="container">
        <h2>Create Patient:</h2>
        <input type="text" placeholder="Patient Name" />
        <input type="text" placeholder="Patient Surname" />
        <input type="text" placeholder="Patient ID" />
        <input type="text" placeholder="Patient DOB" />

        <button @click=${this.goToHistoryPage}>Continue to history</button>
        <button @click=${this.goBack}>Back</button>
      </div>
    `;
  }

  goToHistoryPage() {
    sessionStorage.setItem('lastPage','create-patient-page');

    console.log("history button clicked");
    // Replace the current patient-options-page with create-patient-page
const createhistory = document.createElement('patient-history-page');
this.replaceWith(createhistory);
  }

  goBack() {
    const lastPage = sessionStorage.getItem('lastPage');
    if (lastPage) {
        const lastPageElement = document.createElement(lastPage);
        this.replaceWith(lastPageElement);
    }
  }
}
