// patient-history-page.ts
import { LitElement, html, css } from 'lit';
import { customElement } from 'lit/decorators.js';

import './wound-photo-page';

@customElement('patient-history-page')
export class PatientHistoryPage extends LitElement {
  static styles = css`
    .container {
      padding: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-family: Arial, sans-serif;
    }
    h2 {
      margin-top: 20px;
      margin-bottom: 10px;
      font-size: 20px;
      color: #333;
    }
    .scrollable-area {
      width: 80%;
      height: 400px;
      overflow-y: scroll;
      margin-bottom: 16px;
      background-color: #f0f0f0;
      padding: 8px;
      border-radius: 4px;
    }
    .scrollable-area input, .scrollable-area select, .scrollable-area textarea {
      width: 100%;
      padding: 8px;
      margin-bottom: 12px;
      border: 1px solid #ccc;
      border-radius: 4px;
    }
    .scrollable-area label {
      display: block;
      margin-bottom: 6px;
      font-weight: bold;
    }
    .checkbox-container {
      display: flex;

    }
    .checkbox-container label {
      margin-left: 8px;
    }
    .checkbox-item {
      display: flex;
      align-items: center;
      margin-bottom: 8px; /* Adjust this value to control vertical spacing */
    }
    button {
      width: 300px;
      padding: 12px;
      background-color: #2196f3;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      margin: 15px 0;
    }
  `;

  render() {
    return html`
      <div class="container">
        <h2>Patient History, Symptoms, and Test Results</h2>
        <div class="scrollable-area">
          <label for="dob">Patient Date of Birth:</label>
          <input type="date" id="dob" @change=${this._calculateAge}>

          <label for="age">Age:</label>
          <input type="text" id="age" readonly>

          <label for="sex">Sex:</label>
          <select id="sex">
            <option value="male">Male</option>
            <option value="female">Female</option>
          </select>

          <label for="wound_occurrence">Date of Wound Occurrence:</label>
          <input type="date" id="wound_occurrence" @change=${this._calculateWoundDuration}>

          <label for="duration">Duration of Wound (months):</label>
          <input type="text" id="duration" readonly>

          <label for="wound_history">Wound Treatment History:</label>
          <textarea id="wound_history" placeholder="Enter treatment history..."></textarea>

          <label for="height">Height (m):</label>
          <input type="number" id="height" @change=${this._calculateBMI} step="0.01">

          <label for="weight">Weight (kg):</label>
          <input type="number" id="weight" @change=${this._calculateBMI}>

          <label for="bmi">Body Mass Index (BMI):</label>
          <input type="text" id="bmi" readonly>


          <div id="serum_container" style="display: none;">
          <label for="serum_albumin">Serum Albumin (g/L):</label>
          <input type="number" id="serum_albumin">
          </div>


          <label for="blood_pressure">Blood Pressure (mmHg):</label>
          <input type="text" id="blood_pressure" placeholder="e.g., 120/80">

          <label for="blood_glucose">Blood Glucose (mmol/L):</label>
          <input type="number" id="blood_glucose">

          <label for="haemoglobin">Plasma Hemoglobin (g/dL):</label>
          <input type="number" id="haemoglobin">

           <label for="comorbidities">Comorbidities:</label>
          <div class="checkbox-container">
            <div class="checkbox-item">
              <input type="checkbox" id="diabetes">
              <label for="diabetes">Diabetes</label>
            </div>
            <div class="checkbox-item">
              <input type="checkbox" id="hypertension">
              <label for="hypertension">Hypertension</label>
            </div>
            <div class="checkbox-item">
              <input type="checkbox" id="cardiac_failure">
              <label for="cardiac_failure">Cardiac Failure</label>
            </div>
            <div class="checkbox-item">
              <input type="checkbox" id="kidney_disease">
              <label for="kidney_disease">Chronic Kidney Disease</label>
            </div>
            <div class="checkbox-item">
              <input type="checkbox" id="malnutrition">
              <label for="malnutrition">Malnutrition</label>
            </div>
            <div class="checkbox-item">
              <input type="checkbox" id="lung_disease">
              <label for="lung_disease">Chronic Lung Disease</label>
            </div>
            <div class="checkbox-item">
              <input type="checkbox" id="immune_compromise">
              <label for="immune_compromise">Immune Compromise</label>
            </div>
            <div class="checkbox-item">
              <input type="checkbox" id="haematopoietic_disease">
              <label for="haematopoietic_disease">Haematopoietic Disease</label>
            </div>
            </div>

            <label for="ankle_pressure">Ankle Pressure (mmHg):</label>
          <input type="number" id="ankle_pressure" @change=${this._calculateABPI}>

          <label for="brachial_pressure">Brachial Pressure (mmHg):</label>
          <input type="number" id="brachial_pressure" @change=${this._calculateABPI}>

          <label for="abpi">Ankle-Brachial Pressure Index (ABPI):</label>
          <input type="text" id="abpi" readonly>

          <label for="toe_pressure">Toe Pressure Index (mmHg):</label>
          <input type="number" id="toe_pressure">

          <label for="gfr">Glomerular Filtration Rate (ml/min):</label>
          <input type="number" id="gfr" @change=${this._checkGFR}>

          <div class="checkbox-container">
          <div class="checkbox-item">

          <input type="checkbox" id="hiv" @change=${this._checkHIV}>
          <label for="hiv">HIV:</label>
           </div>
          </div>

        <div id="cd4_container" style="display: none;">
    <label for="cd4_count">Clusters of Differentiation 4 (CD4) count (cells/mm3):</label>
    <input type="number" id="cd4_count">
        </div>


          <div class="checkbox-container">
          <div class="checkbox-item">
          <input type="checkbox" id="haemophilia" @change=${this._checkHaemophilia}>
          <label for="haemophilia">Haemophilia:</label>
          </div>
          </div>

          <label for="monofilament">Monofilament Testing:</label>
          <input type="number" id="monofilament" placeholder="Areas tested negative (out of 10)" @change=${this._checkMonofilament}>

          <label>Nail Conditions:</label>
          <div class="checkbox-container">
            <div class="checkbox-item">
              <input type="checkbox" id="ingrown_nail">
              <label for="ingrown_nail">Ingrown Nail</label>
            </div>
            <div class="checkbox-item">
              <input type="checkbox" id="fungal_infection">
              <label for="fungal_infection">Fungal Infection</label>
            </div>
            <div class="checkbox-item">
              <input type="checkbox" id="nail_pitting">
              <label for="nail_pitting">Nail Pitting</label>
            </div>
            <div class="checkbox-item">
              <input type="checkbox" id="onchomycosis">
              <label for="onchomycosis">Onychomycosis</label>
            </div>
            <div class="checkbox-item">
              <input type="checkbox" id="nail_discoloration">
              <label for="nail_discoloration">Nail Discoloration</label>
            </div>
          </div>


          <label>Skin Conditions:</label>
          <div class="checkbox-container">
            <div class="checkbox-item">
              <input type="checkbox" id="xerosis">
              <label for="xerosis">Xerosis</label>
            </div>
            <div class="checkbox-item">
              <input type="checkbox" id="fissures">
              <label for="fissures">Fissures</label>
            </div>
            <div class="checkbox-item">
              <input type="checkbox" id="eczema">
              <label for="eczema">Eczema</label>
            </div>
            <div class="checkbox-item">
              <input type="checkbox" id="psoriasis">
              <label for="psoriasis">Psoriasis</label>
            </div>
            <div class="checkbox-item">
              <input type="checkbox" id="dermatitis">
              <label for="dermatitis">Dermatitis</label>
            </div>
          </div>


          <label>Foot Deformities:</label>
          <div class="checkbox-container">
            <div class="checkbox-item">
              <input type="checkbox" id="hallux_valgus">
              <label for="hallux_valgus">Hallux Valgus</label>
            </div>
            <div class="checkbox-item">
              <input type="checkbox" id="bunionette">
              <label for="bunionette">Bunionette</label>
            </div>
            <div class="checkbox-item">
              <input type="checkbox" id="claw_toe">
              <label for="claw_toe">Claw Toe</label>
            </div>
            <div class="checkbox-item">
              <input type="checkbox" id="hammer_toe">
              <label for="hammer_toe">Hammer Toe</label>
            </div>
            <div class="checkbox-item">
              <input type="checkbox" id="flat_foot">
              <label for="flat_foot">Flat Foot</label>
            </div>
          </div>


          <label>Medications & Treatments:</label>
          <div class="checkbox-container">
            <div class="checkbox-item">
              <input type="checkbox" id="immuno_suppressive">
              <label for="immuno_suppressive">Immuno-suppressive</label>
            </div>
            <div class="checkbox-item">
              <input type="checkbox" id="cytotoxic">
              <label for="cytotoxic">Cytotoxic</label>
            </div>
            <div class="checkbox-item">
              <input type="checkbox" id="antibiotic">
              <label for="antibiotic">Antibiotic</label>
            </div>
            <div class="checkbox-item">
              <input type="checkbox" id="anticoagulant">
              <label for="anticoagulant">Anticoagulant</label>
            </div>
            <div class="checkbox-item">
              <input type="checkbox" id="steroid">
              <label for="steroid">Steroid</label>
            </div>
          </div>
        </div>


        <button @click="${this._submitHistory}">Enter and Continue</button>
        <button @click="${this._goBack}">Back</button>
      </div>
    `;
  }

  _calculateAge() {
    const dobElement = this.shadowRoot?.getElementById('dob') as HTMLInputElement | null;
    const ageElement = this.shadowRoot?.getElementById('age') as HTMLInputElement | null;

    if (dobElement && ageElement) {
      const dob = dobElement.value;
      if (dob) {
        const birthDate = new Date(dob);
        const currentDate = new Date();
        const age = currentDate.getFullYear() - birthDate.getFullYear();
        ageElement.value = age.toString();

        // Handle flagging if age is outside normal range
        if (age < 5 || age > 70) {
          console.warn('Flag: Age outside normal range');
        }
      }
    }
  }

  _calculateWoundDuration() {
    const woundDateElement = this.shadowRoot?.getElementById('wound_occurrence') as HTMLInputElement | null;
    const durationElement = this.shadowRoot?.getElementById('duration') as HTMLInputElement | null;

    if (woundDateElement && durationElement) {
      const woundDate = woundDateElement.value;
      if (woundDate) {
        const woundDateObj = new Date(woundDate);
        const currentDate = new Date();
        const duration = (currentDate.getFullYear() - woundDateObj.getFullYear()) * 12
                       + (currentDate.getMonth() - woundDateObj.getMonth());
        durationElement.value = duration.toString();

        // Flag chronic wound if duration > 3 months
        if (duration > 3) {
          console.warn('Flag: Chronic wound (duration > 3 months)');
        }
      }
    }
  }

  _calculateBMI() {
    const heightElement = this.shadowRoot?.getElementById('height') as HTMLInputElement | null;
    const weightElement = this.shadowRoot?.getElementById('weight') as HTMLInputElement | null;
    const bmiElement = this.shadowRoot?.getElementById('bmi') as HTMLInputElement | null;
    const serumcontainer = this.shadowRoot?.getElementById('serum_container') as HTMLInputElement ;
    if (heightElement && weightElement && bmiElement) {
      const height = parseFloat(heightElement.value);
      const weight = parseFloat(weightElement.value);

      if (height && weight) {
        const bmi = weight / (height * height);
        bmiElement.value = bmi.toFixed(2);

        // Handle flagging if BMI is outside normal range
        if (bmi < 18.5 || bmi > 24.9) {
          console.warn('Flag: BMI outside normal range');
          if (bmi<18.5){

            serumcontainer.style.display = 'block';
          }
          else{serumcontainer.style.display = 'none';}
        }
      }
    }
  }

  _calculateABPI() {
    const anklePressureElement = this.shadowRoot?.getElementById('ankle_pressure') as HTMLInputElement | null;
    const brachialPressureElement = this.shadowRoot?.getElementById('brachial_pressure') as HTMLInputElement | null;
    const abpiElement = this.shadowRoot?.getElementById('abpi') as HTMLInputElement | null;

    if (anklePressureElement && brachialPressureElement && abpiElement) {
      const anklePressure = parseFloat(anklePressureElement.value);
      const brachialPressure = parseFloat(brachialPressureElement.value);

      if (anklePressure && brachialPressure) {
        const abpi = anklePressure / brachialPressure;
        abpiElement.value = abpi.toFixed(2);

        // Flags based on ABPI value
        if (abpi > 1.3) {
          console.warn('Toe Pressure Index: open up assessment section.');
        } else if (abpi >= 0.81 && abpi <= 0.9) {
          console.warn('Flag: Consider special precautions for wound management.');
        }
      }
    }
  }

  _checkGFR() {
    const gfrElement = this.shadowRoot?.getElementById('gfr') as HTMLInputElement | null;

    if (gfrElement) {
      const gfr = parseFloat(gfrElement.value);
      if (gfr < 60) {
        console.warn('Flag: Assess kidney function more closely.');
      }
    }
  }

  _checkHIV() {
    const hivElement = this.shadowRoot?.getElementById('hiv') as HTMLInputElement | null;
    const cd4Container = this.shadowRoot?.getElementById('cd4_container') as HTMLInputElement ;
    if (hivElement && hivElement.checked) {
      console.warn('Flag: Consider advanced assessment due to HIV status.');
      cd4Container.style.display = 'block'; // Show CD4 input
    }
    else {
      cd4Container.style.display = 'none'; // Show CD4 input
    }

  }

  _checkCD4() {
    const cd4Element = this.shadowRoot?.getElementById('cd4_count') as HTMLInputElement | null;

    if (cd4Element) {
      const cd4Count = parseInt(cd4Element.value, 10);
      if (cd4Count < 200) {
        console.warn('Flag: Immunocompromised state detected.');
      }
    }
  }

  _checkHaemophilia() {
    const haemophiliaElement = this.shadowRoot?.getElementById('haemophilia') as HTMLInputElement | null;

    if (haemophiliaElement && haemophiliaElement.checked) {
      console.warn('Flag: Monitor for bleeding risks associated with haemophilia.');
    }
  }

  _checkMonofilament() {
    const monofilamentElement = this.shadowRoot?.getElementById('monofilament') as HTMLInputElement | null;

    if (monofilamentElement) {
      const negativeTests = parseInt(monofilamentElement.value, 10);
      if (negativeTests > 6) {
        console.warn('Flag: Consider advanced wound assessment or referral.');
      }
    }
  }

  _submitHistory() {
    // Here, handle the submission logic
    sessionStorage.setItem('lastPage', 'patient-history-page');
    const woundPhotoPage = document.createElement('wound-photo-page');
    this.replaceWith(woundPhotoPage);
    console.log("Patient history submitted.");
  }

  _goBack() {
    const lastPage = sessionStorage.getItem('lastPage');
    if (lastPage) {
      const lastPageElement = document.createElement(lastPage);
      this.replaceWith(lastPageElement);
    }
  }
}
